import _checkTypes from "check-types";
import _makeRequest2 from "./utils/makeRequest";
import _assignParams2 from "./utils/assignParams";
import _jsonParser2 from "./utils/jsonParser";
import _constants2 from "./config/constants";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Modules from the community: package.json
 */
var check = _checkTypes;
/**
 * Internal modules
 */

var _makeRequest = _makeRequest2;
var _assignParams = _assignParams2;
var _jsonParser = _jsonParser2;
var _constants = _constants2;
var ACCEPTED_PARAMS = _constants.ACCEPTED_PARAMS;
var GOOGLEMAPS_ENDPOINTS = _constants.GOOGLEMAPS_ENDPOINTS;
var MAX_REQUEST_LENGTHS = _constants.MAX_REQUEST_LENGTHS;
var METHOD_KEY = "place-autocomplete";

exports = function (params, callback) {
  if (typeof callback !== "function") {
    throw new TypeError("callback must be present");
  }

  if ((this || _global).config.key == null) {
    return callback(new Error("The placeAutocomplete API requires a key. You can add it to the config."));
  }

  if (!check.object(params)) {
    return callback(new TypeError("params must be an object"));
  }

  var args = _assignParams({}, params, ACCEPTED_PARAMS[METHOD_KEY]);

  if (args.input == null) {
    return callback(new Error("params.input is required"));
  }

  return _makeRequest((this || _global).request, (this || _global).config, GOOGLEMAPS_ENDPOINTS[METHOD_KEY], args, _jsonParser(callback), MAX_REQUEST_LENGTHS[METHOD_KEY]);
};

export default exports;