import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Buffer = _buffer.Buffer;

/**
 * Returns the default configuration object
 */
exports = function () {
  return {
    encode_polylines: true,
    google_client_id: null,
    google_channel: null,
    key: null,
    proxy: null,
    secure: false,
    stagger_time: 200,

    set google_private_key(value) {
      if (typeof value !== "undefined" && value !== null) {
        // Google private keys are URL friendly base64, needs to be replaced with base64 valid characters
        (this || _global)._googlePrivateKey = value.replace(/-/g, "+").replace(/_/g, "/");
        (this || _global)._googlePrivateKey = new Buffer((this || _global)._googlePrivateKey, "base64");
      } else {
        (this || _global)._googlePrivateKey = null;
      }
    },

    get google_private_key() {
      return (this || _global)._googlePrivateKey || null;
    }

  };
};

export default exports;