import _checkTypes from "check-types";
import _makeRequest2 from "./utils/makeRequest";
import _assignParams2 from "./utils/assignParams";
import _jsonParser2 from "./utils/jsonParser";
import _constants2 from "./config/constants";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Modules from the community: package.json
 */
var check = _checkTypes;
/**
 * Internal modules
 */

var _makeRequest = _makeRequest2;
var _assignParams = _assignParams2;
var _jsonParser = _jsonParser2;
var _constants = _constants2;
var ACCEPTED_PARAMS = _constants.ACCEPTED_PARAMS;
var GOOGLEMAPS_ENDPOINTS = _constants.GOOGLEMAPS_ENDPOINTS;
var MAX_REQUEST_LENGTHS = _constants.MAX_REQUEST_LENGTHS;
var MAX_RADIUS = 50000;
var PLACES_RANKBY_DEFAULT = "prominence";
var PLACES_RANKBY_DISTANCE = "distance";
var METHOD_KEY = "place-search-nearby";

exports = function (params, callback) {
  if (typeof callback !== "function") {
    throw new TypeError("callback must be present");
  }

  if ((this || _global).config.key == null) {
    return callback(new Error("The placeSearchNearby API requires a key. You can add it to the config."));
  }

  if (!check.object(params)) {
    return callback(new TypeError("params must be an object"));
  }

  var args = _assignParams({}, params, ACCEPTED_PARAMS[METHOD_KEY]);

  if (args.location == null) {
    return callback(new Error("params.location is required"));
  }

  if (args.rankby !== PLACES_RANKBY_DEFAULT && args.rankby !== PLACES_RANKBY_DISTANCE) {
    args.rankby = PLACES_RANKBY_DEFAULT;
  }
  /*
    Note that radius must not be included if rankby=distance
    Ranking results by distance will set a fixed search radius of 50km
  */


  if (args.rankby === PLACES_RANKBY_DISTANCE) {
    if (args.keyword == null && args.name == null && args.types == null) {
      return callback(new Error("If rankby=distance is specified, then one or more of keyword, name, or types is required."));
    }

    delete args.radius;
  } else if (args.rankby === PLACES_RANKBY_DEFAULT) {
    if (args.radius == null) {
      args.radius = MAX_RADIUS;
    }
  }

  if (args.radius != null) {
    args.radius = Math.min(parseInt(args.radius, 10), MAX_RADIUS);
  }

  if (args.minprice != null) {
    args.minprice = parseInt(args.minprice, 10);

    if (args.minprice < 0 || args.minprice > 4) {
      args.minprice = 0;
    }
  }

  if (args.maxprice != null) {
    args.maxprice = parseInt(args.maxprice, 10);

    if (args.maxprice < 0 || args.maxprice > 4) {
      args.maxprice = 4;
    }

    if (args.minprice > args.maxprice) {
      var swap = args.maxprice;
      args.maxprice = ags.minprice;
      args.minprice = swap;
    }
  }

  return _makeRequest((this || _global).request, (this || _global).config, GOOGLEMAPS_ENDPOINTS[METHOD_KEY], args, _jsonParser(callback), MAX_REQUEST_LENGTHS[METHOD_KEY]);
};

export default exports;