var exports = {};

exports = function (num) {
  var encodeString = "";
  var nextValue, finalValue;

  while (num >= 32) {
    nextValue = (32 | num & 31) + 63;
    encodeString += String.fromCharCode(nextValue);
    num >>= 5;
  }

  finalValue = num + 63;
  encodeString += String.fromCharCode(finalValue);
  return encodeString;
};

export default exports;